$(document).ready(function () {

  /*  ORDER SOCKET*/

  let user_id = $('.orders').attr('data-user-id');

  io.socket.post('/orders/sign_main_room', {room_name: 'room-' + user_id}, function (res_dann, jwres) {
    //console.log(res_dann);

  });

  io.socket.on('api', function (dann) {

    //console.log(dann);

    io.socket.post('/orders', {json: true}, function (res_dann, jwres) {

      // console.log(res_dann);
      $('.main_list_order').empty();

      if (res_dann.orders) {

        res_dann.orders.forEach(function (el, ind) {

          create_main_order(el);
        });

      }else{
        create_main_order(false);
      }
      if (res_dann.user.orders) {
        res_dann.user.orders.forEach(function (el, ind) {

          create_main_order(el);
        });
      }else{
        create_main_order(false);
      }
      list_prav();

    });

  });

  io.socket.on('new_order', function (order) {

    // console.log(order);
    let dann = order.order;

    create_order(dann);

    list_prav();
  });


  io.socket.on('update_order', function (order) {
    // console.log(order);
    let dann = order.order;

    create_main_order(dann);

    list_prav();

  });

  /* END  ORDER SOCKET*/


  io.socket.on('user_api', function (dann) {

    io.socket.post('/orders/user_dann', {json: true}, function (res_dann, jwres) {

      // console.log(res_dann);
      if (!_.isEmpty(res_dann)) {
        $('.toolbar .op_dep').text(res_dann.deposit + "р");
        $('.toolbar .average').text(res_dann.average);
        $('.toolbar .group').text(res_dann.group);

      }


    });

  });

});

